@use 'vars.scss';

h1 {
    margin: 1.5rem 0 0.5rem;
    font-size: 1.6rem;
    font-weight: 600;
}
h2 {
    margin: 1.2rem 0 0.25rem;
    font-size: 1.5rem;
    font-weight: 600;
}
h3 {
    margin: 0.5rem 0 0.25rem;
    font-size: 1.3rem;
    font-weight: 500;
}

p {
    margin: 0 0 1.5rem;
}

a {
    color: #0969da;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

ul {
    margin: 0.5em 0;
}

main {
    .chronological {
        .entry {
            display: flex;
            margin-bottom: 1rem;
        }

        .date {
            min-width: 10rem;
            text-align: right;
            font-style: italic;
        }

        .details {
            margin-left: 1rem;
        }
    }
}

@media screen and (max-width: vars.$desktop-content-width) {
    main {
        .chronological {
            .entry {
                flex-direction: column;
            }

            .date {
                text-align: left;
            }

            .details {
                margin-left: 0;
            }
        }
    }
}
