@use 'vars.scss';

#index {
    #about-me {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        margin: 3rem 0 3rem;

        img {
            width: 9rem;
            height: 9rem;
            border-radius: 50%;
        }

        .text {
            margin-left: 2rem;
            font-size: 1.6rem;

            strong {
                font-size: 2rem;
            }
        }
    }

    #interests {
        padding-left: 1.5rem;

        li {
            list-style-type: none;
            line-height: 1.6em;
        }
    }
}

@media screen and (max-width: vars.$desktop-content-width) {
    #index {
        #about-me {
            flex-direction: column;

            img {
                width: 12rem;
                height: 12rem;
            }

            .text {
                width: 100%;
                margin: 2rem 0 0 0;
            }
        }

        #interests {
            padding-left: 0;
        }
    }
}
