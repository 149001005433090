$box-padding-lr: 1rem; // main boxes padding left and right

@use 'vars.scss';

html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

header {
    background-color: #e3eef5;

    #header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: vars.$desktop-content-width;
        margin: 0 auto;
        padding: 0.7em $box-padding-lr;
    }

    #header-content > a {
        font-size: 1.5rem;
        font-weight: 300;
        color: #000;
    }

    nav {
        ul {
            display: flex;
        }

        li {
            display: block;
        }

        a {
            padding: 0.8rem 1rem;
            border-radius: 0.4rem;
            font-size: 1.1rem;
            text-decoration: none;

            &:hover {
                background-color: rgba(0, 0, 0, 0.07);
                text-decoration: none;
            }
        }
    }
}

main {
    width: vars.$desktop-content-width;
    margin: 1rem auto 2rem;
    padding: 0 $box-padding-lr;
    font-size: 1.3rem;
}

footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    width: vars.$desktop-content-width;
    margin: 0 auto;
    padding: 0 $box-padding-lr 0.5rem $box-padding-lr;

    p.text {
        margin: 0;
    }

    .icons, .text {
        padding: 0.3em 0;
    }

    .icons {
        padding-left: 0;

        li {
            display: inline-block;
            margin-right: 1rem;
        }

        a {
            font-size: 2rem;
            color: #000;
            text-decoration: none;

            &.fa-linkedin {
                color: #0068bc;
            }
            &.fa-twitter {
                color: #009de9;
            }
        }
    }
}

@media screen and (max-width: vars.$desktop-content-width) {
    header #header-content, main, footer {
        width: inherit;
    }

    header .lastname {
        display: none;
    }

    footer {
        flex-direction: column;
        justify-content: center;

        .icons li {
            margin-left: 1rem;
        }
    }
}

.layout-post {
    h1 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }

    h2, h3 {
        code {
            font-size: 0.9em;
        }
    }

    li, p {
        code {
            font-size: 0.8em;
            background-color: #afb8c133;
            white-space: nowrap;
        }
    }

    pre {
        font-size: 1rem;
    }
}
